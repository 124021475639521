.ant-btn {
  font-size: 16px;
  border-radius: 4px;
  height: auto;

  &:hover,
  &:focus {
    color: $lightBlue;
    border-color: $lightBlue;
  }
}

.ant-btn-primary {
  background: $darkBlue;
  border-color: $darkBlue;

  &:hover,
  &:focus {
    color: $white;
    background: $darkBlueHover;
    border-color: $darkBlueHover;
  }
}

.ant-btn-danger {
  &:hover,
  &:focus {
    color: #fff;
    border-color: #ff7875;
    background: #ff7875;
  }
}
