.layout {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px !important;
    background: #fff !important;
    border-bottom: 1px solid #f0f0f0;

    &__logo {
      float: left;
      height: 54px;
      width: auto;
      margin: 5px 12px 5px 0;
    }

    &__left-menu {
      flex: 2;
    }

    &__right-menu {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    .ant-menu-submenu {
      a {
        color: rgba(0, 0, 0, 0.85);
      }

      &.ant-menu-submenu-selected {
        a {
          color: #1890ff;
        }
      }
    }
  }

  &__content {
    background: #fff;
    min-height: 100vh;
    padding: 25px 10px;

    @media only screen and (max-width: 1200px) {
      padding: 25px 25px;
    }

    @media only screen and (max-width: 768px) {
      padding: 25px 10px;
    }

    @media only screen and (min-width: 1200px) {
      padding: 25px 2%;
    }
  }
}
