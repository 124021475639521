.ant-picker {
  border-radius: 4px;
}

.ant-picker-input {
  font-size: 16px;

  & > input {
    font-size: 16px;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: $darkBlue;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $darkBlue;
}

.ant-picker-today-btn {
  color: $lightBlue;

  &:hover {
    color: $lightBlueHover;
  }
}
