
// input

.ant-input,
.ant-input-number {
  font-size: 16px;
  border-radius: 4px;

  &:focus,
  &-focused {
    border-color: $lightCyan;
    box-shadow: 0 0 0 2px $lightCyanShadow;
  }

  &:hover {
    border-color: $lightCyan;
  }
}

.ant-input-number-input {
  height: 35.14px;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-input-search-button {
  height: 35.14px;
}

// picker

.ant-picker {
  &:hover {
    border-color: $lightCyan;
  }

  &-focused {
    border-color: $lightCyan;
    box-shadow: 0 0 0 2px $lightCyanShadow;
  }
}

// select

.ant-select {
  font-size: 16px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $lightCyan;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $lightCyan;
  box-shadow: 0 0 0 2px $lightCyanShadow;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35.14px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 33.14px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 33.14px;
}

// checkbox

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #29599a;
}

.ant-checkbox-checked {
  &:after {
    border-color: #29599a;
  }

  .ant-checkbox-inner {
    background-color: #29599a;
    border-color: #29599a;
  }
}


// form

.ant-form {
  font-size: 16px;

  label {
    font-size: 16px;
  }
}

.ant-form-item-label > label {
  font-size: 16px;
}
