.ant-pagination-item-active {
  border-color: $lightBlue;

  a {
    color: $lightBlue;
  }
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: $lightBlue;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: $lightBlue;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $lightBlue;
  border-color: $lightBlue;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $lightBlue;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}
