.ant-radio-group-solid
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: $darkBlue;
  background-color: $darkBlue;
  color: $white;

  &:hover {
    border-color: $darkBlueHover;
    background-color: $darkBlueHover;
    color: $white;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: $darkBlue;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: $darkBlue;
}

.ant-radio-button-wrapper {
  &:hover {
    color: $darkBlue;
  }
}

.ant-radio-button-wrapper-green.ant-radio-button-wrapper-checked {
  background-color: #8ac951 !important;
  border-color: #8ac951 !important;
  color: $white !important;

  &:before,
  &:hover::before {
    background-color: #8ac951;
  }
}

.ant-radio-button-wrapper-red.ant-radio-button-wrapper-checked {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  color: $white !important;

  &:before,
  &:hover::before {
    background-color: #ff4d4f;
  }
}
