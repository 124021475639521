a {
  color: $lightBlue;

  &:active,
  &:hover {
    color: $lightBlueHover;
  }
}

.ant-breadcrumb {
  font-size: 16px;

  a {
    &:hover {
      color: $lightBlueHover;
    }
  }

  & > span:last-child {
    color: $lightBlueHover;
  }
}
