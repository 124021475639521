.dashboard-container {
  .ant-table-wrapper {
    border: 1px solid #f0f0f0;
  }

  .ant-form-item-label {
    min-width: 100px;
  }

  .combined-revenue-table {
    .ant-table-thead {
      .table__cell-actual {
        font-weight: bold;
        background: #19365e;
        color: #fff;
      }

      .table__cell-goal {
        font-weight: bold;
        background: #35859c;
        color: #fff;
      }
    }

    .ant-table-tbody {
      .table__cell-actual {
        font-weight: bold;
        background: #bcd1ed;
      }

      .table__cell-goal {
        font-weight: bold;
        background:#b7dce6;
      }
    }
  }
}
