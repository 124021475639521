.content-container {
  & & {
    &__title {
      margin-bottom: 12px;
    }

    &__subtitle {
      margin-bottom: 8px;
    }

    &__breadcrumb {
      margin-bottom: 24px;
    }

    &__form, &__search-form {
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      margin-bottom: 36px;
      padding: 24px;

      .form-item-currency, .form-item-percentage {
        .ant-form-item-control {
          padding-left: 37.14px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 37.14px;
            height: 37.14px;
            color: #000000d9;
            text-align: center;
            line-height: 35.14px;
            box-sizing: border-box;
            background: #fafafa;
            border: 1px solid #d9d9d9;
            border-right: 0;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }

          .ant-input-number {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      .form-item-currency {
        .ant-form-item-control {
          &:before {
            content: '$';
          }
        }
      }

      .form-item-percentage {
        .ant-form-item-control {
          &:before {
            content: '%';
          }
        }
      }
    }

    &__search-form {
      background: #fafafa;
    }

    &__section {
      margin-bottom: 60px;
    }

    &__subsection {
      margin-bottom: 30px;
    }

    &__table {
      .table__row-grey-bold {
        td {
          background: #fafafa;
          font-weight: 500;
        }

        &:hover {
          td {
            background: #fafafa;
            font-weight: 500;
          }
        }
      }

      .table__row-highlight-teal {
        td {
          font-weight: bold;
          background:#b7dce6;
        }

        &:hover {
          td {
            font-weight: bold;
            background:#b7dce6;
          }
        }
      }

      .table__row-highlight-blue {
        td {
          font-weight: bold;
          background: #bcd1ed;
        }

        &:hover {
          td {
            font-weight: bold;
            background: #bcd1ed;
          }
        }
      }

      .table__row-highlight-blue-total-row {
        td:first-child {
          background: #19365e;
          color: #fff;
        }
      }
    }
  }
}
