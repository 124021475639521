.goal-list-container {
  & & {
    &__filter {
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      margin-bottom: 36px;
      padding: 24px;
    }

    &__filter-item {
      width: 95%;
    }

    &__select {
      width: 100%;
    }

    &__create-button {
      margin-top: 30px;
      display: inline-block;
    }
  }
}
