.authentication-container {
  width: 750px;
  margin: 0 auto;
  padding-top: 20vh;

  & & {
    &__title {
      font-weight: normal;
    }

    &__logo {
      width: 100%;
      height: auto;
      padding-right: 20px;
    }

    &__form {
      padding-left: 40px;
      border-left: 1px solid rgba(0, 0, 0, .1);
    }
  }
}
